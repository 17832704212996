#editor {
    display: flex;
    height: 100%;
}

.column {
    background: rgb(255, 255, 255);
    border-radius: 15px;
    margin: 10px 20px;
    min-width: 300px;
    min-height: 500px;
    flex-grow: 1;
}

.column > .title {
    font-size: 16px;
    font-weight: normal;;
    margin: -10px;
    border-radius: 100px;
    color: #fff;
    display: inline-block;
    box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.15);
}

.column > .title > p {
    position: relative;
    margin: 0;
    float: left;
    background: hsl(344, 100%, 54%);
    padding: 12px 17px 7px;
    border-radius: 100px;
    z-index: 1;
}

.column > .title > p:nth-of-type(2n) {
    margin-left: -20px;
    padding-left: 30px;
    border-radius: 0 100px 100px 0;
    background: hsl(350, 30%, 34%);
    z-index: 0;
}

.column h2 {
    margin: 20px 20px 10px 20px;
    font-size: 15px;
    font-weight: normal;
}

.column > .content {
    height: calc(100% - 130px);
}

.list {
    margin: 30px 0 0;
    height: 100%;
    overflow-y: auto;
}

.template{
    background:  rgb(243, 243, 243);
    padding: 10px;
    border-radius: 10px;
    margin: 10px 20px;
    cursor: pointer;
}

.template > p.name {
    margin: 0;
}

/* Button */
button.button {
    padding: 13px 13px 8px;
    margin: 20px;
    font-size: 14px;
    font-family: inherit;;
    background: #ff1453;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 30px;
}

/* editor within a column */
/* todo: rename */
.fields{
    margin: 30px 0 0 0;
    height: calc(100% - 30px);
}

.textField{
    padding: 5px 10px;
    backgroudn: #fff;
    border: 1px solid rgb(228, 228, 228);
    border-radius: 5px;
    width: calc(100% - 62px);
    margin: 0pc 20px 10px 20px;
}

.instructions {
    background: rgb(251, 251, 251);
    padding: 10px;
    background: rgb(251, 251, 251);
    padding: 10px;
    overflow-y: auto;
    max-height: calc(100% - 20px);
}

.instruction {
    margin: 10px;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: 0px 1px 5px -2px rgba(0,0,0,0.08);
    border-radius: 5px;
    background: #fff;
}

.instruction > .options {
    border-bottom: 1px solid #eee;
    overflow: hidden;
}

.instruction > .options > p {
    padding: 5px 8px;
    font-size: 13px;
    border-right: 1px solid #eee;
    float: left;
    margin: 0;
}

.instruction > .options > p > input {
    width: 20px;
    margin: 0;
    padding: 0;
    border: none;
}

.instruction > textarea {
    width: calc(100% - 10px);
    margin: 0px;
    border: none;
    border-bottom: 1px solid rgb(238, 238, 238);
    border-radius: 5px 5px 0px 0px;
    padding: 5px;
    max-width: calc(100% - 10px);
    min-width: calc(100% - 10px);
    min-height: 30px;
    max-height: 200px;
}

.instruction > .ingredients {
    overflow: hidden;
}

.instruction > .ingredients > p{
    float: left;
    margin: 5px;
    background: #f3f3f3;
    padding: 5px;
    border-radius: 3px;
    font-size: 13px;
}

.instruction > .ingredients > p > span{
    padding: 0 2px;
}